import { Router } from '@reach/router'
import axios from 'axios'
import React from 'react'
import getTokenData from 'Root/Shared/utils/getGraphqlValidationToken'

import { ptConfigs } from '../../../puxconfig'
import { getRepeaterQuery } from '../../../Shared/fragments/repeater-fragments.js'
import { getCustomFragment } from '../../../Shared/fragments/widget-fragments.js'
import { getLocalizedData } from '../../../Shared/utils/localeURL'
import getSideCardWidgets from '../../../Shared/utils/previewGetSideCardWidgets.js'
import { WidgetData } from '../components/builders/WidgetBuilder'
import PuxHomePage from '../templates/HomePage/PuxHomePage'

const localizedConfigData = getLocalizedData(
  process.env.LOCALE as string,
  process.env.PROJECT as string
)

interface PreviewPageProps {
  contentitemid: string
  contentitemversionid: string
  path: string
  type: string
}

interface PreviewPageState {
  contentType: string
  contentItemId: string
  contentItemVersionId: string
  // authorizationToken: string
  documentData: null | {
    data: {
      orchard: {
        [key: string]: any
      }
    }
    originalData: {
      contentType: string
      displayText: string
      path: string
      [key: string]: any
    }
    pageContext: {
      widgets?: [WidgetData]
    }
  }
}

class PreviewPage extends React.Component<PreviewPageProps, PreviewPageState> {
  constructor(props: PreviewPageProps) {
    super(props)

    this.state = {
      contentType: props.type,
      contentItemId: props.contentitemid,
      contentItemVersionId: props.contentitemversionid,
      // authorizationToken: ``,
      documentData: null,
    }
  }

  componentDidMount() {
    buildPage(
      this.state.contentType,
      this.state.contentItemId,
      this.state.contentItemVersionId,
      this
    )
  }

  renderPreview() {
    if (this.state.documentData) {
      switch (this.state.documentData.originalData.contentType) {
        case `EasySoftwarePage`:
          return (
            <EasySoftwarePage
              data={this.state.documentData.data}
              pageContext={this.state.documentData.pageContext}
            />
          )
        case `PuxHomePage`:
          return (
            <PuxHomePage
              data={this.state.documentData.data}
              pageContext={this.state.documentData.pageContext}
            />
          )
        case `EasySoftwareArticle`:
          return (
            <PreviwArticlePage
              pageContext={this.state.documentData.pageContext}
              data={this.state.documentData.data}
            />
          )
        case `EasySoftwareCaseStudy`:
          return (
            <PreviewCaseStudyDetail
              data={this.state.documentData.data}
              pageContext={this.state.documentData.pageContext}
            />
          )
        case `EasySoftwareFunction`:
          return (
            <PreviewFunctionDetail
              data={this.state.documentData.data}
              pageContext={this.state.documentData.pageContext}
            />
          )
        case `EasySoftwareNews`:
          return (
            <PreviewNewsDetail
              data={this.state.documentData.data}
              pageContext={this.state.documentData.pageContext}
            />
          )
        case `EasySoftwareCategoryPage`:
          return (
            <PreviewCategoryPage
              data={this.state.documentData.data}
              pageContext={this.state.documentData.pageContext}
            />
          )
        case `PuxLandingPage`:
          return (
            <PreviewLandingPage
              data={this.state.documentData.data}
              pageContext={this.state.documentData.pageContext}
            />
          )
        default:
          break
      }
    }
  }
  // `${process.env.GATSBY_API_URL}`
  render() {
    // axios
    //   .get(`https://localhost:44318/connect/preview`, {
    //     headers: {
    //       'Content-Type': `application/json`,
    //     },
    //   })
    //   .then((response) => {
    //     console.log(response)
    //   })

    return <div>{this.renderPreview()}</div>
  }
}

const Previews = () => {
  return (
    <div>
      <Router>
        <PreviewPage path='/preview/:type/:contentitemid/:contentitemversionid' />
      </Router>
    </div>
  )
}

export default Previews

const getBaseQuery = (contentType, sections) => {
  return `{
    ${contentType}%pathCondition% {
      path
      displayText
      contentType
      contentItemVersionId
      contentItemId
      localization {
        culture
      }
      puxCategory {
        termContentItemIds
      }
      ${sections
      .map((section) => {
        return `${section} {
          widgets {
            ... on PuxSection {
              contentItemId
              contentType
              sectionUseContainer
              sectionIsModal
              sectionModalId
              sectionBordersBetweenWidgets
              puxBackgroundDefinition {
                backgroundDefinitionPartColor
              }
              puxWidgetProperties {
                widgetPropertiesPartID
                widgetPropertiesPartMarginTop
                widgetPropertiesPartPaddingTop
                widgetPropertiesPartMarginBottom
                widgetPropertiesPartPaddingBottom
              }
              flow {
                widgets {
                  %baseSectionQueryContentType%
                  %widgetFragmentsPlaceholder%
                }
              }
            }
          }
        }`
      })
      .join(`\n`)}
  }
}`
}

const camelize = function camelize(str) {
  return str.charAt(0).toLowerCase() + str.slice(1)
}

const buildPage = async (type, contentItemId, contentItemVersionId, _this) => {
  const PT = ptConfigs[type]
  const camelcaseType = camelize(type)
  const baseQuery = getBaseQuery(PT.codeName, PT.zones)

  const completeQuery = baseQuery
    .replace(
      `%baseSectionQueryContentType%`,
      `
          contentType
          contentItemId
        `
    )
    .replace(`%widgetFragmentsPlaceholder%`, ``)
    .replace(
      `%pathCondition%`,
      `(where: {contentItemId: "${contentItemId}", contentItemVersionId: "${contentItemVersionId}"}
        status: DRAFT)`
    )

  const tokenData = await getTokenData()

  axios
    .post(
      `${process.env.GATSBY_API_URL}`,
      `query ${completeQuery}`.replace(/\n/g, ``),
      {
        headers: {
          'Content-Type': `application/graphql`,
          'Access-Control-Allow-Origin': `*`,
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      }
    )
    .then(async (response) => {
      const data = response.data.data[PT.codeName][0]
      const path = data.path
      const culture = data.localization.culture

      PT.zones.map(async (zone) => {
        if (data[zone].widgets != undefined) {
          await getWidgetsFromOrchardWidgetZone(
            data,
            data[zone],
            baseQuery.replace(
              `%pathCondition%`,
              `(where: {contentItemId: "${contentItemId}", contentItemVersionId: "${contentItemVersionId}"}
                          status: DRAFT)`
            ),
            path,
            PT.codeName,
            zone,
            culture,
            _this
          )
        }
      })

      if (PT.zones.length == 0) {
        const orchardData = {
          documentData: {
            data: {
              orchard: {},
            },
            originalData: data,
            pageContext: {},
          },
        }
        orchardData.documentData.data.orchard[PT.codeName] = [data]

        // add sidecards
        const nodeTermContentItemId = data.puxCategory.termContentItemIds[0]
        if (nodeTermContentItemId) {
          const sideCardWidgets = await getSideCardWidgets(
            nodeTermContentItemId,
            culture,
            tokenData
          )
          orchardData.documentData.pageContext.sideCardWidgets = sideCardWidgets
        }

        orchardData.documentData.pageContext.breadcrumbs = [
          {
            displayText: `Preview`,
            path: `/`,
          },
        ]

        _this.setState(orchardData)
      }
    })
    .catch((error) => error)
}

const getWidgetsFromOrchardWidgetZone = async (
  pageData,
  zone,
  baseQuery,
  path,
  documentType,
  widgetZoneName = `flow`,
  culture,
  _this
) => {
  const dataTemplate = {}

  zone.widgets.map((section) => {
    dataTemplate[section.contentItemId] = []
    section.flow.widgets.map((widget) => {
      if (dataTemplate[section.contentItemId].indexOf(widget.contentType) < 0) {
        dataTemplate[section.contentItemId].push(widget.contentType)
      }
    })
  })

  Promise.all(
    Object.keys(dataTemplate).map(async (sectionID) => {
      const sectionObject = Object.assign(
        {},
        zone.widgets.filter((sectionWidget) => {
          return sectionWidget.contentItemId === sectionID
        })[0]
      )

      sectionObject.template = zone.widgets.filter((template) => {
        return template.contentItemId === sectionObject.contentItemId
      })[0].flow.widgets
      sectionObject.flow.widgets = []
      return Promise.all(
        dataTemplate[sectionID].map(async (widgetFragment) => {
          return new Promise(async (resolve) => {
            const widgetQuery = baseQuery
              .replace(/%baseSectionQueryContentType%/g, ``)
              .replace(
                /%widgetFragmentsPlaceholder%/g,
                getCustomFragment(widgetFragment).replace(`Orchard_`, ``)
              )
              .replaceAll(/Orchard_/g, ``)

            const tokenData = await getTokenData()
            axios
              .post(
                `${process.env.GATSBY_API_URL}`,
                `query ${widgetQuery}`.replace(/\n/g, ``),
                {
                  headers: {
                    'Content-Type': `application/graphql`,
                    'Access-Control-Allow-Origin': `*`,
                    Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
                  },
                }
              )
              .then((data) => {
                const orchardNode = data.data
                if (orchardNode.errors) {
                  return
                }

                if (orchardNode.data) {
                  orchardNode.data[documentType][0][widgetZoneName].widgets
                    .filter((section) => {
                      return section.contentItemId === sectionID
                    })[0]
                    .flow.widgets.filter((widget) => {
                      return Object.keys(widget).length > 0
                    })
                    .map(async (widget) => {
                      await mapRepeater(widget, culture)
                      sectionObject.flow.widgets.push(widget)
                      resolve(sectionObject)
                    })
                } else {
                  resolve(sectionObject)
                }
              })
          })
        })
      )
    })
  ).then(async (widgetData) => {
    // add widgets
    const data = {
      documentData: {
        data: {
          orchard: {},
        },
        originalData: pageData,
        pageContext: {
          widgets: {},
        },
      },
    }

    data.documentData.pageContext.widgets[widgetZoneName] = widgetData.map(
      (widgetsGroup) => widgetsGroup[0]
    )

    // widgetsData.map(widgetData => {
    //   data.documentData.pageContext.widgets[widgetData] =
    // })

    // add document data
    data.documentData.data.orchard[documentType] = [pageData]

    // add sidecards
    const nodeTermContentItemId = pageData.puxCategory.termContentItemIds[0]
    if (nodeTermContentItemId) {
      const sideCardWidgets = await getSideCardWidgets(
        nodeTermContentItemId,
        culture,
        await getTokenData()
      )
      data.documentData.pageContext.sideCardWidgets = sideCardWidgets
    }

    _this.setState(data)
  })
}

const mapRepeater = async (widget, culture) => {
  // PuxRepeater - begin
  if (widget.contentType === `PuxSimpleRepeater`) {
    const termIds = widget.puxSimpleFilter.simpleFilterTermIDs.termContentItemIds
      .map((item) => `"${item}"`)
      .join(`,`),
      contentType = widget.puxSimpleFilter.simpleFilterContentType,
      explicitContentItemIds = widget.puxSimpleFilter.simpleFilterExplicitIDs,
      sortByIds = widget.puxSimpleFilter.simpleFilterSortByIDs,
      fallbackCulture = widget.simpleRepeaterFallbackCulture,
      pageSize = widget.simpleRepeaterPageSize || 3,
      onePage = widget.simpleRepeaterOnePageOnly

    const shouldUseExplicitItems =
      !sortByIds &&
      explicitContentItemIds &&
      explicitContentItemIds.contentItemIds &&
      explicitContentItemIds.contentItemIds.length // explicit content items selected

    if (
      ((termIds && termIds.length) || shouldUseExplicitItems) &&
      contentType
    ) {
      const fallbackCultureString =
        fallbackCulture == undefined || !fallbackCulture.html
          ? ``
          : `, fallbackCulture: "${fallbackCulture.html}"`
      const taxonomies = !shouldUseExplicitItems
        ? await getRepeaterTaxonomies(
          termIds,
          contentType,
          culture,
          fallbackCultureString
        )
        : undefined
      // todo: fix eslint for ternary operators !!
      /* eslint-disable indent */
      const itemIdArray = !shouldUseExplicitItems
        ? taxonomies.data.taxonomyContentItem.map((item) => item.contentItemId)
        : explicitContentItemIds.contentItemIds.map((item) => item)
      /* eslint-disable indent */

      const itemsQuery = await getRepeaterItems(
        contentType,
        itemIdArray,
        onePage ? pageSize : itemIdArray.length,
        culture,
        !shouldUseExplicitItems
      )

      const itemsData = itemsQuery.data
      const rootNodeName = Object.keys(itemsData)[0]
      widget.repeaterData = itemsData[rootNodeName]

      // sort results by preselected ids

      if (sortByIds) {
        // easiest way to sort by shorter array? indexOf returns -1 for not found, thus they are always sorted in front and reversed, so we are "de-reversing" them
        // for that, we need to reverse sorting array first, otherwise it would be sorted in opposite direction
        // should be in O(n)
        explicitContentItemIds.contentItemIds.reverse()
      }

      if (shouldUseExplicitItems || sortByIds) {
        widget.repeaterData.sort((a, b) =>
          explicitContentItemIds.contentItemIds.indexOf(a.contentItemId) >
            explicitContentItemIds.contentItemIds.indexOf(b.contentItemId)
            ? 1
            : -1
        )
      }

      if (sortByIds) {
        // easiest way to sort by shorter array? indexOf returns -1 for not found, thus they are always sorted in front and reversed, so we are "de-reversing" them
        widget.repeaterData.reverse()
      }
    }
  }
  // PuxRepeater - end
}

const getRepeaterTaxonomies = async (
  termIds,
  contentType,
  culture,
  fallbackCultureString
) => {
  const taxonomiesQuery = `
        query TaxonomyContentItemsQuery {
            taxonomyContentItem(
              where: {termContentItemId_in: [${termIds}], contentType: "${contentType}", culture: "${culture}" ${fallbackCultureString}}
            ) {
              contentItemId
            }
        }
      `

  const tokenData = await getTokenData()
  const result = await axios
    .post(
      `${process.env.GATSBY_API_URL}`,
      `${taxonomiesQuery}`.replace(/\n/g, ``),
      {
        headers: {
          'Content-Type': `application/graphql`,
          'Access-Control-Allow-Origin': `*`,
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      }
    )
    .then((taxonomies) => {
      return taxonomies.data
    })

  return result
}

const getRepeaterItems = async (
  contentType,
  itemIdArray,
  limit,
  culture,
  sort
) => {
  const taxonomiesQuery = `
      query RepeaterContentItemsQuery {
          ${getRepeaterQuery(
    contentType,
    itemIdArray,
    culture,
    limit,
    sort
  ).replace(`Orchard_`, ``)}
      }
    `
  const tokenData = await getTokenData()
  return await axios
    .post(
      `${process.env.GATSBY_API_URL}`,
      `${taxonomiesQuery}`.replace(/\n/g, ``),
      {
        headers: {
          'Content-Type': `application/graphql`,
          'Access-Control-Allow-Origin': `*`,
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      }
    )
    .then((items) => {
      return items.data
    })
}

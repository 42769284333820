export const getCustomFragment = (key) => {
  const queryWidgetProperties = `
    puxWidgetProperties {
      widgetPropertiesPartID
      widgetPropertiesPartMarginBottom
      widgetPropertiesPartMarginTop
      widgetPropertiesPartPaddingBottom
      widgetPropertiesPartPaddingTop
      widgetPropertiesPartMobileOrder
    }
    metadata {
      alignment
      size
    }
  `

  const queryWidgetAnimation = `
    puxWidgetAnimation {
      widgetAnimationPartType
      widgetAnimationPartIsEnabled
    }
  `

  const queryButtonProperties = `
    buttonColor
    buttonDisabled
    buttonNewWindow
    buttonType
    buttonOpenModalId
    buttonLink {
      url
      internal
      text
    }
  `

  const queryImagePaths = `
    resizePaths(width: 1920, mode: "Min", quality: 75)
    resizePathsMobile(width: 768, mode: "Min", quality: 75)
    resizePathsTablet(width: 1200, mode: "Min", quality: 75)
    texts
  `

  const queryImageBoxList = `
    contentItems {
      ... on Orchard_PuxImageBox {
        contentType
        contentItemId
        displayText
        imageBoxContent {
          html
        }
        imageBoxImage {
          mediaSelectorImage {
            ${queryImagePaths}
          }
          mediaSelectorLightbox
        }
        imageBoxButton {
          ${queryButtonProperties}
        }
      }
    }
  `

  const queryMediaVideoProperties = `
    file {
      paths
    }
    muted
    autoplay
    loop
    controls
    width
    height
  `

  const queryPuxVideoProperties = `
    videoStructuredDataContentUrl
    videoStructuredDataDescription
    videoStructuredDataName
    videoStructuredDataUploadDate
    videoThumbnail {
      ${queryImagePaths}
    }
    videoYTID {
      html
    }
  `

  switch (key) {
    case `PuxWysiwyg`:
      return `
        ... on Orchard_PuxWysiwyg {
          contentType
          contentItemId
          wysiwygSmallContainer
          htmlBody {
            html
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareBenefits`:
      return `
        ... on Orchard_EasySoftwareBenefits {
          contentType
          contentItemId
          displayText
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          benefitsList {
            ${queryImageBoxList}
          }
        }
      `

    case `EasySoftwareCards`:
      return `
        ... on Orchard_EasySoftwareCards {
          contentType
          contentItemId
          displayText
          headingsColor
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          cardsList {
            contentItems {
              ... on Orchard_EasySoftwareCardItem {
                contentItemId
                contentType
                displayText
                cardItemPerex {
                  html
                }
                cardButtonPrimary {
                  ${queryButtonProperties}
                }
                cardButtonSecondary {
                  ${queryButtonProperties}
                }
              }
              ... on Orchard_EasySoftwareProductCard {
                contentItemId
                contentType
                displayText
                productCardPerex {
                  html
                }
                productCardBackground {
                  mediaSelectorImage {
                    ${queryImagePaths}
                  }
                }
                puxButton {
                  ${queryButtonProperties}
                }
                puxVideo {
                  ${queryPuxVideoProperties}
                }
              }
            }
          }
        }
      `

    case `EasySoftwareCaseStudies`:
      return `
        ... on Orchard_EasySoftwareCaseStudies {
          contentType
          contentItemId
          displayText
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          caseStudiesList {
            contentItems {
              ... on Orchard_EasySoftwareCaseStudy {
                contentType
                contentItemId
                displayText
                path
                caseStudyPerex {
                  html
                }
                caseStudyImage {
                  mediaSelectorImage {
                    ${queryImagePaths}
                  }
                }
                caseStudyParameters {
                  contentItems {
                    ... on Orchard_PuxKeyValue {
                      displayText
                      contentItemId
                      keyValueContent {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `

    case `EasySoftwareFeatures`:
      return `
        ... on Orchard_EasySoftwareFeatures {
          contentType
          contentItemId
          displayText
          featuresPerex {
            html
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          featuresMainButton {
            ${queryButtonProperties}
          }
          featuresSecondaryButton {
            ${queryButtonProperties}
          }
          featuresList {
            ${queryImageBoxList}
          }
        }
      `

    case `EasySoftwareNavigator`:
      return `
        ... on Orchard_EasySoftwareNavigator {
          contentType
          contentItemId
          displayText
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          navigatorCategories {
            contentItems {
              ... on Orchard_EasySoftwareNavigatorCategory {
                displayText
                navigatorSubcategories {
                  contentItems {
                    ... on Orchard_EasySoftwareNavigatorSubcategory {
                      displayText
                      navigatorSubcategoryText {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `

    case `EasySoftwareTable`:
      return `
        ... on Orchard_EasySoftwareTable {
          contentType
          contentItemId
          displayText
          tableContent {
            html
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `PuxGallery`:
      return `
        ... on Orchard_PuxGallery {
          displayText
          contentItemId
          contentType
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          galleryItemsToLightbox
          galleryList {
            contentItems {
              contentItemId
              contentType
              ... on Orchard_PuxGalleryItem {
                contentType
                contentItemId
                galleryItemImage {
                  ${queryImagePaths}
                }
              }
            }
          }
        }
      `

    case `PuxGalleryWrapper`:
      return `
        ... on Orchard_PuxGalleryWrapper {
          displayText
          contentItemId
          contentType
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          galleryWrapperItemsToLightbox
          galleryWrapperItemsSet {
            contentItems {
              ... on Orchard_PuxGallerySet {
                contentType
                contentItemId
                displayText
                gallerySetItems {
                  mediaSelectorImage {
                    ${queryImagePaths}
                  }
                  mediaSelectorTitle {
                    html
                  }
                }
              }
            }
          }
        }
      `

    case `PuxTopImage`:
      return `
        ... on Orchard_PuxTopImage {
          displayText
          contentItemId
          contentType
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          topImageContent {
            html
          }
          topImageBenefit1 {
            html
          }
          topImageBenefit2 {
            html
          }
          topImageContentAlign
          topImageContentColor
          topImageButtonAlign
          topImageImageStyle
          topImageButton {
            ${queryButtonProperties}
          }
          topImageSecondaryButton {
            ${queryButtonProperties}
          }
          topImageBackground {
            mediaSelectorImage {
              ${queryImagePaths}
            }
          }
          puxVideo {
            ${queryPuxVideoProperties}
          }
        }
      `

    case `EasySoftwareSolutions`:
      return `
        ... on Orchard_EasySoftwareSolutions {
          contentType
          contentItemId
          displayText
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          solutionsList {
            ${queryImageBoxList}
          }
        }
      `

    case `EasySoftwareReviews`:
      return `
        ... on Orchard_EasySoftwareReviews {
          contentType
          contentItemId
          displayText
          reviewsText
          reviewsImage {
            ${queryImagePaths}
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          reviewsList {
            contentItems {
              contentItemId
              contentType
              displayText
              ... on Orchard_EasySoftwareReview {
                displayText
                contentItemId
                contentType
                reviewContent {
                  html
                }
                reviewReviewerCompany {
                  html
                }
                reviewReviewerName {
                  html
                }
                reviewReviewerPosition {
                  html
                }
                reviewRatingList {
                  contentItems {
                    contentItemId
                    contentType
                    ... on Orchard_PuxRating {
                      contentItemId
                      contentType
                      displayText
                      ratingStars
                      ratingMaxStars
                    }
                  }
                }
              }
            }
          }
        }
      `

    case `PuxImageContent`:
      return `
        ... on Orchard_PuxImageContent {
          contentItemId
          contentType
          imageContentText {
            html
          }
          imageContentButtonAlign
          imageContentImagePosition
          puxMediaSelector {
            mediaSelectorImage {
              ${queryImagePaths}
            }
            mediaSelectorLightbox
          }
          puxButton {
            ${queryButtonProperties}
          }
          puxVideo {
            ${queryPuxVideoProperties}
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `PuxNumbersContainer`:
      return `
        ... on Orchard_PuxNumbersContainer {
          contentType
          contentItemId
          numbersContainerShowProgress
          displayText
          puxNumbersContainerItems {
            contentItems {
              ... on Orchard_PuxNumber {
                numberDescription
                numberPrefix
                numberUnit
                numberValue
                contentItemId
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `PuxVideoContainer`:
      return `
        ... on Orchard_PuxVideoContainer {
          displayText
          contentType
          contentItemId
          videoContainerList {
            contentItems {
              ... on Orchard_PuxVideo {
                puxVideo {
                  videoStructuredDataContentUrl
                  videoStructuredDataDescription
                  videoStructuredDataName
                  videoStructuredDataUploadDate
                  videoYTID {
                    html
                  }
                  videoThumbnail {
                    ${queryImagePaths}
                  }
                }
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
        `

    case `PuxSimpleRepeater`:
      return `
        ... on Orchard_PuxSimpleRepeater {
          contentType
          contentItemId
          simpleRepeaterPageSize
          simpleRepeaterPagerOptions
          simpleRepeaterOnePageOnly
          simpleRepeaterFallbackCulture {
            html
          }
          puxSimpleFilter {
            simpleFilterTermIDs {
              termContentItemIds
            }
            simpleFilterExplicitIDs {
              contentItemIds(first: 250)
            }
            simpleFilterContentType
            simpleFilterSortByIDs
            puxFilterIsHidden
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareSideCardTrial`:
      return `
        ... on Orchard_EasySoftwareSideCardTrial {
          displayText
          contentType
          contentItemId
          sideCardTrialText {
            html
          }
          sideCardTrialButtonText
        }
      `

    case `EasySoftwareSideCardNewsletter`:
      return `
        ... on Orchard_EasySoftwareSideCardNewsletter {
          displayText
          contentType
          contentItemId
          sideCardNewsletterText {
            html
          }
        }
      `

    case `EasySoftwareSideCardCustom`:
      return `
        ... on Orchard_EasySoftwareSideCardCustom {
          displayText
          contentType
          contentItemId
          sideCardCustomText {
            html
          }
          sideCardCustomButton {
            ${queryButtonProperties}
          }
        }
      `

    case `EasySoftwareSideCardNavigation`:
      return `
        ... on Orchard_EasySoftwareSideCardNavigation {
          displayText
          contentType
          contentItemId
          sideCardNavigationLinks {
            contentItems {
              ... on Orchard_EasySoftwareSideCardNavigationLink {
                contentType
                contentItemId
                sideCardNavigationLinkIcon
                sideCardNavigationLinkNewWindow
                sideCardNavigationLinkItem {
                  internal
                  text
                  url
                }
              }
            }
          }
        }
      `

    case `EasySoftwareIconBar`:
      return `
        ... on Orchard_EasySoftwareIconBar {
          contentItemId
          contentType
          iconBarIconList {
            contentItems {
              ... on Orchard_EasySoftwareIconBarItem {
                contentItemId
                displayText
                iconBarItemImage {
                  ${queryImagePaths}
                }
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareEmbeddedCodeContainer`:
      return `
        ... on Orchard_EasySoftwareEmbeddedCodeContainer {
          contentType
          contentItemId
          embeddedCodeContainerItems {
            contentItems {
              ... on Orchard_EasySoftwareEmbeddedCode {
                contentType
                contentItemId
                embeddedCodeShortCode {
                  html
                }
                embeddedCodeType
              }
              ... on Orchard_EasySoftwareDangerousEmbeddedCode {
                contentType
                contentItemId
                dangerousEmbeddedCodeSourceCode {
                  html
                }
                dangerousEmbeddedCodeType
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareTimeline`:
      return `
        ... on Orchard_EasySoftwareTimeline {
          contentType
          contentItemId
          displayText
          timelineItems {
            contentItems {
              ... on Orchard_EasySoftwareTimelineItem {
                displayText
                contentItemId
                contentType
                timelineItemContent
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareModules`:
      return `
        ... on Orchard_EasySoftwareModules {
          contentType
          contentItemId
          displayText
          modulesItem {
            contentItems {
              ... on Orchard_EasySoftwareModule {
                contentType
                contentItemId
                displayText
                moduleCore {
                  html
                }
                moduleIcon
                moduleLinks {
                  contentItems {
                    ... on Orchard_EasySoftwareSideCardNavigationLink {
                      contentType
                      contentItemId
                      sideCardNavigationLinkIcon
                      sideCardNavigationLinkItem {
                        internal
                        text
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareTestimonials`:
      return `
        ... on Orchard_EasySoftwareTestimonials {
          contentType
          contentItemId
          displayText
          testimonialsList {
            contentItems {
              ... on Orchard_EasySoftwareTestimonial {
                contentType
                contentItemId
                testimonialName {
                  html
                }
                testimonialOccupation {
                  html
                }
                testimonialCompany {
                  html
                }
                testimonialText {
                  html
                }
                testimonialPhoto {
                  mediaSelectorImage {
                    ${queryImagePaths}
                  }
                }
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareVideoBanner`:
      return `
          ... on Orchard_EasySoftwareVideoBanner {
            contentType
            contentItemId
            displayText
            videoBannerVideo {
                ${queryMediaVideoProperties}
              
            }
            videoBannerOverlay
            videoBannerImageStyle
            videoBannerContentAlign
            videoBannerContent {
              html
            }
            videoBannerBackground {
              mediaSelectorImage {
                ${queryImagePaths}
              }
            }
            ${queryWidgetProperties}
            ${queryWidgetAnimation} 
            puxButton {
              ${queryButtonProperties}
            }
          }
        `

    case `EasySoftwareSearchBanner`:
      return `
          ... on Orchard_EasySoftwareSearchBanner {
            contentType
            contentItemId
            displayText
            searchBannerSearchTarget
            searchBannerContent {
              html
            }
            searchBannerContact {
              html
            }
            searchBannerBackground {
              mediaSelectorImage {
                ${queryImagePaths}
              }
            }
            ${queryWidgetProperties}
            ${queryWidgetAnimation}
          }
        `

    default:
      return ``
  }
}
